exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-price-guide-js": () => import("./../../../src/pages/price-guide.js" /* webpackChunkName: "component---src-pages-price-guide-js" */),
  "component---src-pages-private-dentist-exeter-js": () => import("./../../../src/pages/private-dentist-exeter.js" /* webpackChunkName: "component---src-pages-private-dentist-exeter-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-treatments-anti-wrinkle-injections-js": () => import("./../../../src/pages/treatments/anti-wrinkle-injections.js" /* webpackChunkName: "component---src-pages-treatments-anti-wrinkle-injections-js" */),
  "component---src-pages-treatments-collagen-induction-therapy-js": () => import("./../../../src/pages/treatments/collagen-induction-therapy.js" /* webpackChunkName: "component---src-pages-treatments-collagen-induction-therapy-js" */),
  "component---src-pages-treatments-dental-implants-js": () => import("./../../../src/pages/treatments/dental-implants.js" /* webpackChunkName: "component---src-pages-treatments-dental-implants-js" */),
  "component---src-pages-treatments-dermal-fillers-js": () => import("./../../../src/pages/treatments/dermal-fillers.js" /* webpackChunkName: "component---src-pages-treatments-dermal-fillers-js" */),
  "component---src-pages-treatments-facial-aesthetics-treatments-js": () => import("./../../../src/pages/treatments/facial-aesthetics-treatments.js" /* webpackChunkName: "component---src-pages-treatments-facial-aesthetics-treatments-js" */),
  "component---src-pages-treatments-family-dentistry-js": () => import("./../../../src/pages/treatments/family-dentistry.js" /* webpackChunkName: "component---src-pages-treatments-family-dentistry-js" */),
  "component---src-pages-treatments-invisalign-js": () => import("./../../../src/pages/treatments/invisalign.js" /* webpackChunkName: "component---src-pages-treatments-invisalign-js" */),
  "component---src-pages-treatments-lip-fillers-js": () => import("./../../../src/pages/treatments/lip-fillers.js" /* webpackChunkName: "component---src-pages-treatments-lip-fillers-js" */),
  "component---src-pages-treatments-polynucleotide-bio-stimulating-js": () => import("./../../../src/pages/treatments/polynucleotide-bio-stimulating.js" /* webpackChunkName: "component---src-pages-treatments-polynucleotide-bio-stimulating-js" */),
  "component---src-pages-treatments-profhilo-js": () => import("./../../../src/pages/treatments/profhilo.js" /* webpackChunkName: "component---src-pages-treatments-profhilo-js" */),
  "component---src-pages-treatments-skin-boosters-and-mesotherapy-js": () => import("./../../../src/pages/treatments/skin-boosters-and-mesotherapy.js" /* webpackChunkName: "component---src-pages-treatments-skin-boosters-and-mesotherapy-js" */),
  "component---src-pages-treatments-tailored-skincare-js": () => import("./../../../src/pages/treatments/tailored-skincare.js" /* webpackChunkName: "component---src-pages-treatments-tailored-skincare-js" */),
  "component---src-pages-treatments-teeth-whitening-js": () => import("./../../../src/pages/treatments/teeth-whitening.js" /* webpackChunkName: "component---src-pages-treatments-teeth-whitening-js" */),
  "component---src-pages-treatments-veneers-and-bonding-js": () => import("./../../../src/pages/treatments/veneers-and-bonding.js" /* webpackChunkName: "component---src-pages-treatments-veneers-and-bonding-js" */),
  "component---src-templates-bio-js": () => import("./../../../src/templates/bio.js" /* webpackChunkName: "component---src-templates-bio-js" */),
  "component---src-templates-policy-js": () => import("./../../../src/templates/policy.js" /* webpackChunkName: "component---src-templates-policy-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

